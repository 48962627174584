<template>
  <div class="inspire_bg">
    <div class="banner">
      <div class="img-box">
        <img class="img" src="https://df7ocie544p6c.cloudfront.net/images/Inspiration.jpg" alt="" />
      </div>
      <div class="top-left-con">
        <p v-visible='false' class="title">{{ $t("Inspire.title") }}</p>
        <p v-if='$i18n.locale=="cn"' class="title title-tip" v-html="$t('Inspire.title_tip')"></p>
        <p v-else-if='screenWidth<=480 && $i18n.locale=="en"' class='title-en title-tip' v-html="$t('Inspire.title_tip')"></p>
        <p v-else class="title title-tip" style='margin-top: -15%' v-html="$t('Inspire.title_tip')"></p>
      </div>
      <div class="bottom-left-con"></div>
      <div class="line line1"></div>
      <div class="line line2"></div>
      <div class="line line3"></div>
      <div class="line line4"></div>
    </div>
    <div class="content-tits">
      <div class="con">
        <p>
          <span>{{ $t("Inspire.content_one") }}</span
          >{{ $t("Inspire.content_two") }}
        </p>
    </div>
    </div>
    <div class="orange-title">
     <div class="download">
        <a href="/pdf/coaching.pdf" target="_blank" class="download-link">
          <div class="download-txt-container" v-if="screenWidth <= 480">
            <div class="text-item">{{ $t('Inspire.download_short') }}</div>
          </div>
          <div v-else class="download-txt-container">
            <div class="text-item">{{ $t('Inspire.download') }}</div>
          </div>
        </a>
      </div>
    </div>
    <div class="course-video">
      <div class="left">
        <div class="img-box">
          <img class="img-bg" src="https://df7ocie544p6c.cloudfront.net/images/homepage-1.jpg" alt="" />
        </div>
        <div class="tit-box" @click="goPush('/inspire/online-courses')">
          <img
            class="img"
            src="../../assets/images/button-course.png"
            alt=""
          />
          <p>{{ $t("Inspire.txt1") }}</p>
        </div>
        <div class="bottom-con"></div>
        <div class="mantle"></div>
        <div class="click-mantle">
          <!--<img
            v-if="locale"
            src="../../assets/images/button-網上課程2.png"
            alt=""
          />
          <img
            v-else
            src="../../assets/images/button-網上課程-ENG.png"
            alt=""
          />
          <p class="more">
            {{ $t("Inspire.more") }}<i class="el-icon-arrow-right"></i>
          </p>-->
        </div>
      </div>
      <div class="right">
        <div class="img-box">
          <img class="img-bg" src="../../assets/images/banner3.jpg" alt="" />
        </div>
        <div class="tit-box" @click="goPush('/inspire/sports-films')">
          <img
            class="img"
            src="../../assets/images/button-video.png"
            alt=""
          />
          <p>{{ $t("Inspire.txt2") }}</p>
        </div>
        <div class="bottom-con"></div>
        <div class="mantle"></div>
        <div class="click-mantle">
          <!--<img
            v-if="locale"
            src="../../assets/images/svg/button-2.svg"
            alt=""
          />
          <img
            v-else
            src="../../assets/images/button-活動影片-ENG.png"
            alt=""
          />
          <p class="more">
            {{ $t("Inspire.more") }}<i class="el-icon-arrow-right"></i>
          </p>-->
        </div>
      </div>
    </div>
    <div class="content-img">
      <div class="vids-title">
        <p>
          {{ $t("Inspire.video_title") }}
        </p>
      </div>
        <iframe
          :width="screenWidth > 480 ? '800' : '330'"
          :height="screenWidth > 480 ? '460' : '170'"
          src="https://youtube.com/embed/LtTf9aOfbZE"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      locale: false,
      screenWidth: window.document.body.clientWidth,
    };
  },
  methods: {
    goPush(page) {
      let str = window.location.href;
      let index = str.lastIndexOf("#/");
      if (page === str.substring(index + 1, str.length)) {
        return;
      }
      this.$router.push(page);
    },
  },
  created() {
    if (this.$i18n.locale === "cn") {
      this.locale = true;
    }
  },
};
</script>
<style lang="scss" scoped>
.inspire_bg {
  .banner {
    height: 620px;
    position: relative;
    .img-box {
      position: absolute;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 77.5%;
      transform: skew(-13.5deg) translateX(25%);
      overflow: hidden;
      .img {
        position: absolute;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 88%;
        object-fit: cover;
        transform: skew(13deg) translateX(-21%);
      }
    }
    .top-left-con {
      width: 47%;
      height: 75%;
      transform: skew(-13.5deg) translateX(-10.5%);
      position: absolute;
      bottom: 0;
      top: 0;
      background: #28327b;
      z-index: 1;
      color: #fff;
      font-weight: bold;
      // font-family: "NotoSansCJKtc-Black";
      .title {
        font-size: 48px !important;
        transform: skew(6deg) translate(-20%, 40%);
        margin-left: -4.5%;
      }
      .title-tip {
        text-align: left;
        font-size: 32px;
        transform: skew(6deg) translate(18%, 55%);
      }
    }
    .bottom-left-con {
      width: 39%;
      height: 100%;
      transform: skew(-13.5deg) translateX(5%);
      position: absolute;
      bottom: 0;
      left: 0;
      background: #28327b;
    }
    .top-right-con {
      width: 30%;
      height: 19%;
      background: #fff;
      position: absolute;
      top: 0;
      right: 0;
      transform: skew(-15deg) translateX(10%);
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 65%;
        transform: skew(15deg);
        margin-right: 15%;
      }
    }
    .line {
      height: 2PX;
      background: #fff;
      position: absolute;
      z-index: 3;
    }
    .line1 {
      width: 24%;
      top: 8%;
      left: 24%;
    }
    .line2 {
      width: 15%;
      top: 13%;
      left: 22%;
    }
    .line3 {
      width: 11%;
      top: 55%;
      left: 0%;
    }
    .line4 {
      width: 41%;
      top: 63%;
      left: 5%;
    }
  }
  .content-tits{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  
    .con{
      font-size: 26px;
      margin: 3% auto;
      width: 80%;
      letter-spacing: 2px;
      text-align: center;
    }
  }
  .content-img {
    margin: 3% auto;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    flex-wrap: wrap;
    .vids-title {
      font-size: 30px;
      color: #f15922;
      font-weight: bold;
      margin: 30px 0;
      padding-bottom: 50px;
    }
  }
  .orange-title{
    display: flex;
    justify-content: center;
    .download {
      display: flex;
      align-items: center;
      width: 70%;
      height: 100px;
      margin: 45px 0 90px 0;
      background: linear-gradient(
        90deg,
        rgba(241, 89, 34, 1) 0%,
        rgba(241, 89, 34, 1) 35%,
        rgba(241, 89, 34, 0.32566530029980745) 100%
      );
      text-shadow: 2px 0px lightsalmon;
      transform: skew(-15deg);
      object-fit: cover;
      cursor: pointer;
      .text-item {
        transform: skew(15deg);
      }
    }
      .download-link {
        display: block;
        width: 100%;
        font-size: 30px;
        font-weight: bold;
        color: #fff;
        text-align: center;
      }
      .download-link,
      .download-link:hover,
      .download-link:visited,
      .download-link:active {
        text-decoration: none;
        color: white;
      }
      .download-txt-container {
        width: 100%;
        font-size: 28px !important;
        font-weight: bold;
        margin-top: -10px;
        object-fit: cover;
        color: white;
      }
   }
  .steps-line {
    width: 80%;
    margin: 12% auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .round {
      width: 80px;
      height: 80px;
      background: #28327b;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      .x-round {
        width: 45px;
        height: 45px;
        background: #fff;
        border-radius: 50%;
      }
    }
    .line {
      width: 14%;
      height: 8px;
      background: #28327b;
    }
    .line-border {
      width: 20%;
    }
  }
  .click-look {
    width: 100%;
    height: 80px;
    position: relative;
    .left {
      width: 85%;
      height: 100%;
      background: #28327b;
      transform: skew(-10deg) translateX(-2%);
      position: absolute;
      left: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .content {
        transform: skew(10deg);
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 21%;
        font-size: 28px;
        .triangle {
          display: inline-block;
          width: 0;
          height: 0;
          border-top: 14px solid #fff;
          border-right: 8px solid transparent;
          border-left: 8px solid transparent;
          margin: 0 10px;
        }
      }
    }
    .right {
      width: 16.5%;
      height: 100%;
      background: #72ace3;
      transform: skew(-10deg) translateX(4%);
      position: absolute;
      right: 0;
    }
  }
  .course-video {
    width: 100%;
    // height: 380px;
    margin: 1% auto 1%;
    display: flex;
    position: relative;
    // background: red;
    .left {
      position: absolute;
      left: 0;
      width: 52%;
      height: 511px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      overflow: hidden;
      transform: translateX(1%);
      .img-box {
        display: flex;
        width: 100%;
        height: 511px;
        object-fit: cover;
        transform: skew(-6deg) translateX(-4%);
        overflow: hidden;
        .img-bg {
          width: 104%;
          height: 511px;
          object-fit: cover;
          transform: skew(6deg) translateX(4%);
        }
      }
      .bottom-con {
        position: absolute;
        bottom: 0;
        width: 106%;
        height: 15px;
        background: #28327b;
        transform: skew(-8deg) translateX(-9.3%);
        opacity: 1;
      }
      .tit-box {
        cursor: pointer;
        z-index: 5;
        width: 40%;
        position: absolute;
        .img {
          width: 100%;
        }
        p {
          position: absolute;
          top: 20%;
          left: 23%;
          color: #fff;
          // font-family: "MHeiHK-Xbold";
          font-size: 26px;
          font-weight: bold;
        }
      }
      .mantle {
        opacity: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #28327b;
        transform: skew(-6deg) translateX(-4%);
        transition: all ease 0.6s;
      }
      .click-mantle {
        opacity: 0;
        width: 55%;
        position: absolute;
        top: 40%;
        z-index: 1;
        transition: all ease 0.6s;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
        .more {
          color: #fff;
          font-weight: bold;
        }
      }
      &:hover {
        .mantle {
          opacity: .6;
        }
        .click-mantle {
          opacity: 1;
        }
        /*.tit-box {
          display: none;
        }*/
      }
    }
    .right {
      position: absolute;
      right: 0;
      width: 52%;
      height: 511px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      transform: translateX(3%);
      .img-box {
        display: flex;
        width: 99%;
        height: 511px;
        transform: skew(-6deg) translateX(3%);
        overflow: hidden;
        .img-bg {
          width: 100%;
          height: 511px;
          transform: skew(6deg) translateX(-4%);
        }
      }
      .bottom-con {
        position: absolute;
        bottom: 0;
        width: 105%;
        height: 15px;
        background: #f15922;
        transform: skew(-8deg) translateX(3%);
        opacity: 1;
      }
      .tit-box {
        z-index: 5;
        cursor: pointer;
        width: 40%;
        position: absolute;
        .img {
          width: 100%;
        }
        p {
          position: absolute;
          top: 20%;
          left: 20%;
          color: #fff;
          // font-family: "MHeiHK-Xbold";
          font-size: 26px;
          font-weight: bold;
          letter-spacing: 0px;
        }
      }
      .mantle {
        opacity: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background: #f15922;
        transform: skew(-6deg) translateX(3.5%);
        transition: all ease 0.6s;
      }
      .click-mantle {
        opacity: 0;
        width: 55%;
        position: absolute;
        top: 40%;
        z-index: 1;
        transition: all ease 0.6s;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
        .more {
          color: #fff;
        }
      }
      &:hover {
        .mantle {
          opacity: 0.6;
        }
        .click-mantle {
          opacity: 1;
        }
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .inspire_bg {
    .banner {
      height: 392px;
      .img-box {
        width: 85%;
        transform: skew(-14deg) translateX(35%);
        .img {
          width: 100%;
          transform: skew(13deg) translateX(-7%);
        }
      }
      .top-left-con {
        width: 50%;
        height: 72%;
        .title {
          padding-top: 1%;
          font-size: 25px !important;
          transform: skew(6deg) translate(-12%, 27%);
        }
        .title-en {
          padding-top: 5%;
          font-size: 25px !important;
          transform: skew(6deg) translate(-12%, 27%);
        }
        .title-tip {
          letter-spacing: 1px;
          font-size: 24px;
          transform: skew(6deg) translate(17%, 75%);
        }
      }
      .bottom-left-con {
        width: 42%;
        transform: skew(-14deg) translateX(3%);
      }
      .top-right-con {
        width: 30%;
        height: 11%;
      }
      .line1 {
        left: 32%;
      }
      .line2 {
        left: 29%;
      }
    }
    .content-img {
      width: 86%;
      margin: 10% auto;
      flex-direction: column;
      .con {
        width: 100%;
        margin: 0;
        font-size: 30px;
        span {
          font-weight: bold;
        }
      }
      iframe {
        width: 100%;
        margin-left: 0;
      }
    }
    .steps-line {
      width: 90%;
      margin: 15% auto 23%;
      .line-border {
        width: 22%;
      }
      .round {
        width: 25px;
        height: 25px;
        .x-round {
          width: 15px;
          height: 15px;
        }
      }
      .line {
        height: 2px;
      }
    }
    .click-look {
      height: 50px;
      .left {
        width: 92%;
        .content {
          margin-right: 5%;
          font-size: 15px;
          .triangle {
            border-top: 11px solid #fff;
          }
        }
      }
      .right {
        transform: skew(-10deg) translateX(50%);
      }
    }
    .course-video {
      position: static;
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: 40px;
      .left, .right {
        .tit-box {
          p {
            top: 28%;
            left: 0 !important;
            width: 100%;
            font-size: 30px !important;
          }
        }
        .bottom-con {
          height: 20px !important;
        }
      }
      .left {
        width: 100%;
        height: 408px;
        margin-bottom: 2%;
        transform: none;
        overflow: hidden;
        .img-box {
          height: 100%;
          transform: none;
          .img-bg {
            width: 100%;
            height: 100%;
            transform: none;
          }
        }
        .tit-box {
          width: 55%;
          p {
            top: 20%;
            left: 20%;
            font-size: 18px;
          }
        }
        .mantle {
          transform: none;
        }
        .img {
          width: 49%;
        }
        .bottom-con {
          height: 8px;
          width: 100%;
          transform: none;
        }
      }
      .right {
        position: static;
        width: 100%;
        height: 408px;
        transform: translateX(0%);
        .img-box {
          height: 100%;
          width: 100%;
          transform: none;
          .img-bg {
            width: 100%;
            height: 100%;
            transform: none;
          }
        }
        .tit-box {
          width: 55%;
          p {
            top: 20%;
            left: 20%;
            font-size: 18px;
          }
        }
        .bottom-con {
          height: 8px;
          transform: none;
        }
        .mantle {
          transform: none;
        }
        //background: url(../../assets/images/啟發篇4-2.jpg) no-repeat 100%/110%;
        .img {
          width: 47%;
        }
      }
    }
  }
}
</style>
